import { gql } from '@apollo/client';


export const formConfigFields = `
fields {
  ...field
  children {
    ...field
    children {
      ...field
      children {
        ...field
      }
    }
  }
}
`

export const getFieldFragment = (includeFormula)=>`
fragment field on FormFieldConfig {
  internalName
  type
  fieldType
  settings
  ${includeFormula ? "formula" : ""}
  validations
  editable
  name {
    value
  }
  gridConfig
  guide {
    value
  }
}
`

const formQuery = gql`query formConfig($key: String!,$clientId: String!,$date: String!,$language: String,$currency: String) {
 formsOfKey(key: $key,clientId:$clientId,date:$date,language:$language,currency:$currency) {
    config {
         _id
        key
        name {
          value
        }
        grid
        guide  {
          value
        }
        context {
        	category
        }
        type
        chartFields
        fields {
            ...field
            children {
              ...field
              children {
                ...field
                children {
                  ...field
                }
              }
            }
          }
    }
    data {
         _id
        context {
             text
             periodType
        }
        values
    }
 }
}

fragment field on FormFieldConfig {
  internalName
  type
  fieldType
  settings
  formula
  validations
  editable
  name {
    value
  }
  gridConfig
  guide {
    value
  }
}
`;

export default formQuery;

export const formData =  gql`query formData($key: String!,$clientId:String!,$date:String!,$currency:String) {
    formData(key:$key,clientId:$clientId,date:$date,currency:$currency) {
         _id
        context {
             numeric
             financialId
             text
             periodType
        }
        values
    }
}`;

export const formConfigQuery = gql`query formConfig($key: String!,$language: String) {
 formConfig(key: $key,language:$language) {
   
         _id
        key
        name {
          value
        }
        grid
        settings
        guide  {
          value
        }
        context {
        	category
        	periodType
        }
       
        chartFields
        type
        fields {
            ...field
            children {
              ...field
              children {
                ...field
                children {
                  ...field
                }
              }
            }
         }
    }
 
}

fragment field on FormFieldConfig {
  internalName
  type
  fieldType
  settings
  formula
  validations
  editable
  name {
    value
  }
  gridConfig
  guide {
    value
  }
}
`;