import { useQuery } from "@apollo/client"

import { WizardHeader } from "./header";
import { Card, Drawer, Modal, Spin, message } from "antd";
import { Children, useCallback, useContext, useEffect, useState } from "react";
import {StepHeader} from "./stepHeader";
//import '../ReportingWizard/report.scss'
import './reportWizard.scss'
import { FormDataEntry, FormReportEntry } from "../Form/FormDataEntry";
import { useMath } from "../NewFormula/hooks/useMath";
import { useScope } from "../NewFormula/hooks/useScope";
//import { ReportingWizardContext, useReportingWizard } from "./hooks";
import { FormReportFieldEntry } from "./form/FormReportFieldEntry";
import { FormattedMessage } from "react-intl";
import { useApp } from "../../hooks/useApp";
import { FormReportFlat } from "./form/FormReportFlat";
import { ReportingWizardEntry } from "./entry";
import { ReportingWizardFinal } from "./final";
import { ReportingWizardSteps } from "./steps";
import { useWizard } from "./hooks/useWizard";
import { ReportingWizardContext } from "./hooks/context";
import { wizardAndClientFullQuery, wizardFullQuery } from "../../graph/queries/wizard/wizard";
import { useHistory,useLocation } from "react-router-dom";
import ClientContainer, { ClientContext } from "../../ui/containers/client";
import clientQuery from "../../graph/queries/clients/clientQry";
import { FormulaViewerProvider } from "../NewFormula/hooks/useFormulaViewer";
import { FormulaPanel } from "../NewFormula/components/FormulaPanel";

// TODO: Cleanup

const GLOBAL_STEPS = ["entry","steps","final"];

const ReportingWizardContents = ({
    onDone=()=>{}, refetch
})=> {

    // TODO: User dependency
    // If in progress and user is last known reportedBy
    // show the steps directly
    // If different user opens, gets an entry
    // with option to "READ" or to take over the submission


    const {status} = useContext(ReportingWizardContext)
    const [nav,setNav] = useState(null);

    useEffect(()=>{
        setNav( status=="created" || status=="overdue" || status=="submitted" || status=="rejected" ? 0
        : 1);
    },[status])

    switch(nav) {
        case 0:
            return <ReportingWizardEntry onStart={()=>setNav(1)} refetch={refetch}/>
        case 1:
            return <ReportingWizardSteps onEnd={()=>setNav(2)}/>
        case 2:
            return <ReportingWizardFinal onBack={()=>setNav(1)}  onDone={onDone}/>
    }
    return null;
}


const ReportingWizardHoc = ({reportingInstance,children})=> {
    
    const ctx = useWizard(reportingInstance);

    return <ReportingWizardContext.Provider value={ctx}>
        {children}
    </ReportingWizardContext.Provider>

}

/*
  <ReportingWizardHoc reportingInstance={data?.reportingWizard}>
            <ReportingWizardBody steps={steps_clean}/>
            </ReportingWizardHoc>
*/

const ReportingWizardInner = ({client,reportKey,periodKey,onDone}) => {

    
    const {data,loading,refetch} = useQuery(wizardFullQuery, {
        variables:{
            clientId:client._id,
            reportConfigKey:reportKey,
            reportInstanceKey:periodKey,
            language:"en"
        }
    });
 
  
    

    if(loading) {
        return <Card loading={true} className="reporting-wizard" >
         </Card>;
    }

    const {
        config,
        context
     } = data?.wizard;

 

    return <Card className="reporting-wizard"
     title={<WizardHeader clientName={client.name} title={config.name.value} context={context} clientStatus={client.status} cancel={onDone}/>
    }
    >
        <ReportingWizardHoc reportingInstance={data?.wizard}>
<ReportingWizardContents onDone={onDone} refetch={refetch}/>
        </ReportingWizardHoc>
      
      
    </Card>
}


export const ReportingWizard = ({client,reportKey,periodKey}) => {

    const history = useHistory();
   // const location = useLocation()
   // let { from } = location.state || { from: { pathname: "/client/"+client.key } };
	
	/*if(typeof(from)==='string') {
		from = { pathname:from};
	}*/
    const {data,loading,refetch} = useQuery(wizardFullQuery, {
        variables:{
            clientId:client._id,
            reportConfigKey:reportKey,
            reportInstanceKey:periodKey,
            language:"en"
        },
        fetchPolicy:'no-cache'
    });
 
    const onDone = useCallback(()=> {
        history.goBack()
      //  window.location = from.pathname;
       
    },[history])
    

    if(loading) {
        return <div className="full-screen"><Card loading={true} className="reporting-wizard" >
         </Card></div>;
    }

    const {
        config,
        context
     } = data?.wizard;

 

    return <div className="full-screen"><Card className="reporting-wizard"
     title={<WizardHeader clientName={client.name} title={config.name.value} context={context} clientStatus={client.status} cancel={onDone}/>
    }
    >
        <FormulaPanel overflow={false} panelGroupId={"report-wizard-page-formula"}>
        <ReportingWizardHoc reportingInstance={data?.wizard}>
<ReportingWizardContents onDone={onDone} refetch={refetch}/>
        </ReportingWizardHoc>
        </FormulaPanel>
      
      
    </Card></div>
}


export const ReportingWizardModal = ({open,clientId,reportKey,periodKey, onClose=()=>{}})=> {

    
    const {data, loading} = useQuery(clientQuery,{
        variables:{
            clientId
        }
    });



    return <Modal
    maskClosable={false}
    open={open}
    onCancel={onClose}
    width={"95vw"}
    footer={[]}
    >
        {clientId && !loading ? <ClientContext.Provider value={data?.client}>
        <ReportingWizardInner client={data?.client} reportKey={reportKey} periodKey={periodKey} onDone={onClose}></ReportingWizardInner>
        </ClientContext.Provider> : <Spin/>}
       

    </Modal>
}

 const ReportingWizardDrawerInstance = ({open,loading,client,report,onDone,refetch})=> {
   
    const ready = open && !loading  && client && report;
    // width={"80vw"}
    return <Drawer open={open}
        maskClosable={false}
        keyboard={false}
        className="reporting-wizard"
        title={<WizardHeader
        clientName={client?.name}
        context={report?.context}
        title={report?.config?.name?.value}
        clientStatus={client?.status}
        
    />}
            onClose={onDone}
        >
             <FormulaPanel overflow={false} panelGroupId={"report-wizard-drawer-formula"}>
        {ready ?  <ReportingWizardHoc reportingInstance={report}>
            <ReportingWizardContents onDone={onDone} refetch={refetch}/>
        </ReportingWizardHoc> : null}
        </FormulaPanel>
    </Drawer>
}


export const ReportingWizardDrawer = ({open,clientId,reportKey,periodKey, onClose=()=>{}})=> {

    

    const {data,loading,refetch} = useQuery(wizardAndClientFullQuery, {
        variables:{
            clientId:clientId,
            reportConfigKey:reportKey,
            reportInstanceKey:periodKey,
            language:"en"
        },
        fetchPolicy:"cache-and-network"
    });

   
// width={"95vw"}

    return <ClientContext.Provider value={data?.wizard?.client}>
       
        <ReportingWizardDrawerInstance loading={loading} client={data?.wizard?.client}
            report={data?.wizard}
            open={open}
            onDone={onClose}
            refetch={refetch}
        >

        </ReportingWizardDrawerInstance>
      
        </ClientContext.Provider>
}
//<FormulaViewerProvider>
//</FormulaViewerProvider>