import './login.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Meteor from '../../../Meteor';
import {connect} from 'react-redux';
import {Link, useHistory,useLocation} from 'react-router-dom';

import {ToastContainer,ToastMessageAnimated} from 'react-toastr';
import LoginField from '../../components/login/field/index.jsx';
import LoginSelect from '../../components/languageSelector/login';
import {fetchProfile, loggingIn, loggedOut, switchLanguage,login} from '../../../redux/actions/session';
import Loader from '../../components/loader/spinner.jsx';
import { Button } from 'antd';


/**
 * DESCRIPTION: login page
 * ROUTE: /login OR /
 * ACCESSIBLE BY: everyone
 * DATA: N/A
 */
// REMOVE: Should be coming from server
// const SSO_ENABLED = true;
const SSO_REDIRECT_API = "/auth/sso/login"; // DO NOT HARD CODE COMPLETE URL


/**
 * 
 * ADAPT LOGIN FLOW AS FOLLOWING:
 * 1) Username filled in
 * 2) on "next"/lost focus: poll server for available providers (user:services)
 * -- Server decides what login providers to use 
 * -- (So if we want to push all @incofin users to sso, it should be server side config)
 * -- (If we only want select group of @incofin users to sso, also server side decision)
 *  (in test only select users of Incofin have been granted access, handle that as well)
 * 
 * 3) client side gets list of accepted providers and their base config
 * 	- password (no specific config)
 *  - sso_incofin: { } 
 * -- IF BOTH are returned as expected, user decides (no auto forward)
 */


function mapDispatchToProps(dispatch) {
	return {
		
		switchLanguage: (language) => {
			dispatch(switchLanguage(language));
		}
	};
}

function mapStateToProps(state) {
	return {
		language: state.session.language
	};
}

const LoginPage = ({
	language,
	switchLanguage})=> {

	const iamRef = useRef()
	const langRef =useRef()
	const inmybagRef = useRef()
	const containerRef =useRef()
	const location = useLocation();
	const history = useHistory();

  const [preFlight,setPreFlight] = useState(-1);
	const [authenticating,setAuthentication] = useState(false);
	
  const [sso, setSSO] = useState(0);
	const [pass, setPass] = useState(0);
  const [token,setToken] = useState(location && location.search ? location.search : null)

	let { from } = location.state || { from: { pathname: "/" } };
  
  if(typeof(from)==='string') {
		from = { pathname:from};
	}
	if(from.pathname.indexOf('login')>-1) {
		from.pathname='/';
	} 

	useEffect(() => {
    
    let tokenQS = location && location.search ? location.search : null;
    if (tokenQS) {
      tokenQS = tokenQS.slice(1);
      localStorage.setItem("token", tokenQS);
      setToken(tokenQS);
    } else {
      console.log("Normal flow");
    }
  }, [location?.search]);

  useEffect(()=> {
    if(token) {
      setTimeout(()=> {
        //setAuthentication(false);
        console.log("redir", from.pathname);
        window.location = from.pathname;
      },200);
    }
    
  },[token])

	

  // feature/issue-197
  const validateEmail = useCallback((email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  });
  const validateEmailSpecific = useCallback((email) => {
    return String(email).toLowerCase().match(/^[A-Za-z0-9._%+-]+@incofin\.com$/);
  });
 
  const onUserChange = useCallback((e)=>{
    
    if(preFlight==1) {
      setPreFlight(-1);
    }
  },[preFlight]);
  

  // ADAPT: onUserNameChange only on focus loss, not on each keystroke
	const onPreflightCheck = useCallback((e)=>{
    if (!iamRef.current.validate()) {
      return;
    }
    let uName = iamRef.current.getValue();

    setPreFlight(0);
    const data = new URLSearchParams();
    data.append("username", uName);
    fetch("/auth/usercheck", {
      method: "post",
      body: data,
    }).then((response) => response.json())
    .then((data) => {
      const { services } = data;
      const servSSO = services && services.sso ? 1 : 0;
      const servPass = services && services.password ? 1 : 0;
     
      if(servSSO === 1) {
        setPass(0);
        setAuthentication(true);
        window.location.href = SSO_REDIRECT_API;
      } else {
        setPass(servPass);
      }
      setPreFlight(1);
     
      
      
    })
    .catch((e)=> {
      setPreFlight(1);
      setPass(1);
      setSSO(0);
    })
   
    
  },[])

	const PasswordLoginField = ({ authenticating, inmybagRef }) => (
		<LoginField disabled={authenticating} fieldName="inmybag" ref={inmybagRef} label="password"
			placeHolder="type your password here"
			inputType="password"
			validations={
				{
					required: true,
					length: { min: 6 }
				}
			}
		>
		</LoginField>
	);

	const onLogon = useCallback((e)=>{
		e.preventDefault();

		Meteor.logout();

		if (iamRef.current.validate() && inmybagRef.current.validate()) {
			setAuthentication(true);
			const data = new URLSearchParams();
			data.append('username', iamRef.current.getValue());
			data.append('password', inmybagRef.current.getValue());
		
		  
		
			fetch('/auth/login', {
				method: 'post',
				body: data,
			}).then(response => response.json()).then((data)=> {
				const { token } = data;
				localStorage.setItem('token',token);
				setToken(token);
			
			}).catch((e)=> {
				containerRef.current.error("Failed to authenticate");
				setAuthentication(false);
			})
			
		}

		return false
	},[from,iamRef,inmybagRef,containerRef,history]);

	let ToastMessageFactory = React.createFactory(ToastMessageAnimated);

  if(token) {
    return 	<div className="logon-wrapper">
      <section className="logon">
      <div className="action-wrapper"><Loader fadeIn={true} label="authenticating" /> </div>
      </section>
      </div>
  }
	

	return (
		<div className="logon-wrapper">
			<ToastContainer ref={containerRef} toastMessageFactory={ToastMessageFactory} className="toast-top-right"/>

			<section className="logon fadeInUp">
				<div className="logo">
					<span>reporting</span>
				</div>

				<div className="logon-body">
					<form onSubmit={onLogon}>
						<LoginSelect disabled={authenticating || preFlight===0} fieldName="language" ref={langRef} label="language" value={language}
												 action={authenticating ||  preFlight===0 ? ()=>{} : switchLanguage}>
						</LoginSelect>
						<LoginField disabled={authenticating ||  preFlight===0 } fieldName="iam" ref={iamRef} label="email/username"
												placeHolder="type your username here" autoFocus={true}
                        onChange={onUserChange}
												inputType="text"
												validations={
													{
														required: true
													}
												}
						>
						</LoginField>
            
            {preFlight < 1 ? <div className="action-wrapper">
              <Button type="primary" onClick={onPreflightCheck} loading={preFlight===0} >Next</Button>
            </div> : null} 

            {preFlight==1 && pass==1 && sso==0 && iamRef.current.getValue()  ? <><PasswordLoginField authenticating={authenticating} inmybagRef={inmybagRef} />
            <div className="action-wrapper"><Button loading={authenticating} type="primary" htmlType="submit">Login</Button>
            <Link to="/login/resetpassword">Forgot your password?</Link></div>
            </>
            : null }

         
            {authenticating ?  <div className="action-wrapper"><Loader fadeIn={true} label="authenticating" /> </div>: null}

           
					</form>
				</div>
			</section>
		</div>
	);
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
