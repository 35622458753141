import { gql } from '@apollo/client';
// issue-42
export const createExport = gql`mutation createExport($exportType: String, $summaryType: String, $currencies: [String], $clients: [String], $regions: [String], $countries: [String], $periods: Object, $formConfigs: [String], $funds:[String], $clientStatus:[String], $reportStatus: [String], $overdue: String) {
	createExport(exportType: $exportType, summaryType:$summaryType, currencies:$currencies, clients:$clients, regions:$regions, countries:$countries, periods:$periods, formConfigs:$formConfigs, funds:$funds, clientStatus:$clientStatus, reportStatus:$reportStatus, overdue:$overdue)
}
`;
// issue-42
export const createReportsExport = gql`mutation createReportsExport($periods: [Int], $reportStatus: [String], $report: String, $clients: [String], $regions: [String], $countries: [String], $dueDateRange: Object, $clientStatus: [String], $overdue: String) {
	createReportsExport(periods:$periods, reportStatus:$reportStatus, report:$report, clients:$clients, regions:$regions, countries:$countries, dueDateRange:$dueDateRange, clientStatus:$clientStatus, overdue:$overdue)
}`;

export const createExportExchange = gql`mutation createExportExchange($currency: String!, $periods: [Int]) {
	createExportExchange(currency: $currency, periods:$periods)
}
`;

export const createExportCovenants = gql`mutation createExportCovenants($fieldType: String) {
	createExportCovenants(fieldType:$fieldType)
}
`;

export const createExportInvestments = gql`mutation createExportInvestments($periods:Object!, $funds:[String], $regions:[String], $clients:[String], $reportingFrequency:String, $investmentManagers:[String]) {
	createExportInvestments(periods:$periods, funds:$funds, regions:$regions, clients:$clients, reportingFrequency:$reportingFrequency, investmentManagers:$investmentManagers)
}
`;
export const exportTemplate = gql`mutation exportTemplate($reportId: String!, $clientId: String!) {
	exportTemplate(reportId: $reportId, clientId: $clientId)
}
`;