import { useContext } from "react";
import { useApp } from "../../../hooks/useApp";
import { EntryStartSubmission } from "./startSubmission";
import { ReportingWizardContext } from "../hooks/context";
import { EntryStartAudit } from "./startAudit";




export const ReportingWizardEntry = ({onStart, refetch, onPreview})=> {

    const { user } = useApp();//isClient

    const { status,reportedBy,auditedBy } = useContext(ReportingWizardContext)
    
    if(status==="in_progress" && user?._id ===reportedBy?._id) {
        onStart();
    }

    //onPreview

    if(user.isIncofin) {
        switch(status) {
            case "created":
            case "overdue":
            case "rejected":
                return <EntryStartSubmission onStart={onStart} refetch={refetch}/>
                
         /*   case "in_progress":
                return <EntrySubmissionProgress onStart={onStart}/>
            case "rejected":
                return <EntrySubmissionProgress onStart={onStart}/>
    */
            case "submitted": {

                return <EntryStartAudit onStart={onStart} />
            }
            case "in_review": {
               // return <EntryInReviewOther />
            }
        }
    } else {
        if(status==="submitted" || status==="in_review") {
           
            onStart();
        }
        switch(status) {
            case "created":
            case "overdue":
            case "rejected":
                return <EntryStartSubmission onStart={onStart} refetch={refetch}/>
    
        }
    }
    

    return <span>--nothing- {status} </span>

}