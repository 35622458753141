import { Row, Col, Alert, Button, Form, Input, List, Modal, Result, Select,Typography, Steps, Table, Tabs, Divider, Collapse } from "antd"
import { EyeOutlined, SearchOutlined, SettingOutlined,WarningOutlined, CloseSquareOutlined, InfoCircleOutlined, InboxOutlined} from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from "react"
import { ReportingWizardContext } from "../hooks/context";
import { IconReport } from "../icons/IconReport";
import currencies, { currencyMap } from "../../../data/currencies";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { startSubmissionMutation } from "../../../graph/mutations/wizard/index";
import { contextToCalendar } from "../../../ui/components/periodToText";
import { exportTemplate } from "../../../graph/mutations/export";
import { useApp } from "../../../hooks/useApp";
import {Link} from 'react-router-dom';
import '../reportWizard.scss'
const { Panel } = Collapse;

const { Paragraph,Text } = Typography;


const ReportSettingsForm = ({onFormReady})=> {
  const [form] = Form.useForm();
  const {
    currency,scale
  } = useContext(ReportingWizardContext);

  useEffect(() => {
    onFormReady(form);
  }, []);

  return  <Form layout="vertical" form={form} name="report_settings" initialValues={{
    currency,
    scale
  }}>
      <Form.Item label="Currency"
      name="currency"
      rules={[
        {
          required: true,
          message: 'Please select a currency!',
        }
      ]}>
        <Select options={currencies}/>
      </Form.Item>
      <Form.Item label="Scale"
      name="scale"
      rules={[
        {
          required: true,
          message: 'Please select a currency!',
        }
      ]}>
       <Select placeholder="Select a scale to report in">
        <Select.Option value={1}>1/1</Select.Option>
        <Select.Option value={10}>1/10</Select.Option>
        <Select.Option value={100}>1/100</Select.Option>
        <Select.Option value={1000}>1/1000</Select.Option>
        <Select.Option value={10000}>1/10000</Select.Option>
        <Select.Option value={100000}>1/100000</Select.Option>
      </Select>
      </Form.Item>
  </Form>
}

/*
 <List.Item.Meta
         title={}
          description="Ant Design, a design language for background applications, is refined by Ant UED Team"
        />
*/

const ReportList = ({data})=> {
  return <List
  size="small"
    style={{maxHeight:"80px", maxWidth:"600px", overflowY:"scroll"}}
    itemLayout="horizontal"
    dataSource={data}
    grid={{ column: 2 ,xs: 1, sm: 2}}
    renderItem={(item, index) => (
      <List.Item key={index} style={{ display: "flex" }}>
        {item.config?.name?.value} - {contextToCalendar(item.context)}
      </List.Item>
    )}
  />
    
 
}

export const EntryStartSubmission = ({onStart=()=>{}, refetch})=> {
  
   const onPreview =()=>{
    onStart()
   }
   const intl = useIntl();
    const {
      info,currency,scale,steps,changeSettings,client,refreshScope
      ,previousOpenReports
    } = useContext(ReportingWizardContext);

    const [startSubmission,{loading}] = useMutation(startSubmissionMutation)

    const [showSettingsForm,setShowSettingsForm] = useState(false);

    const [formInstance, setFormInstance] = useState();
    const [createTemplate,{}] = useMutation(exportTemplate)
    const [file, setFile] = useState();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const { notify, message } = useApp();
    const [fileError, setFileError] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState("fields");
    const [validationErr, setvalidationError] = useState("");

    const {
      title,clientName,contextText
    } = info
    const {
      _id
    } = client
    const fieldsColumns = [
      {
        title: "Incorrect Field(s)",
        dataIndex: "incorrect",
        key: "incorrect",
        onHeaderCell: () => ({
          style: { backgroundColor: '#f5efef', color: '#000',padding:"12px " }, 
        }),
      },
      {
        title: "Correct Field(s)",
        dataIndex: "correct",
        key: "correct",
        onHeaderCell: () => ({
          style: { backgroundColor: '#f5efef', color: '#000', padding:"12px " }, 
        }),
      }
    ];
    const valuesColumns = [
      {
        title: "Incorrect Value(s)",
        dataIndex: "incorrect",
        key: "incorrect",
        onHeaderCell: () => ({
          style: { backgroundColor: '#f5efef', color: '#000', padding:"12px"}, 
        }),
      },
      {
        title: "Field(s)",
        dataIndex: "field",
        key: "field",
        onHeaderCell: () => ({
          style: { backgroundColor: '#f5efef', color: '#000', padding:"12px" }, 
        }),
      }
    ];
    // const onHandleFileChange = useCallback(async(e)=> {
    //   setFile(e.target.files[0]);
    // });
    const validateFile = (selectedFile) => {
      const validExtensions = ["xls", "xlsx"];
      const fileSizeLimit = 3 * 1024 * 1024; // 3MB in bytes
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!validExtensions.includes(fileExtension)) {
        return "Invalid file type. Only .xls and .xlsx are allowed.";
      }
      if (selectedFile.size > fileSizeLimit) {
        return "File size must be less than 3MB.";
      }
      return null;
    };
    const onHandleFileChange = useCallback(async(e)=> { 
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      setFile(null);
      setFileError("Please select a file.");
      return;
    }
    const error = validateFile(selectedFile);
    if (error) {
      setFile(null);
      setFileError(error);
      return;
    }
    setFile(selectedFile);
    setFileError("");
  }, []);

  const fileDelete = () => {
    setFile(null);
    setFileError("");
  };
    const onHandleUpload = useCallback(async(e)=> {
      e.preventDefault();
      // if (!file) {
      //   // alert('Please select a file first');
      //   notify.error({
      //     message: 'Failed to start!',
      //     description: 'Please upload a file first.',
      //     duration:5
      //   })
      //   return;
      // }
      if (!file) {
        setFileError("Please upload a valid file.");
        return;
      }
      const error = validateFile(file);
      if (error) {
        setFileError(error);
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('reportId', info.id);
      formData.append('clientId', client._id);
      formData.append('numeric', info.numeric);
      formData.append('settings', JSON.stringify({reportingScale:scale, currency}));
      try {
        const response = await fetch('/api/upload', {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        const result = await response.json();
        if(result.success) {
          // console.log('result', result)
          console.log('File uploaded successfully');
          refetch();
          refreshScope();
          onStart();
        } else {
          let viewLink = result && result.validationErr && Object.keys(result.validationErr).length > 0 ? true:false;
          notify.error({
            message: 'Failed to create report!',
            description: (<span>
                  {result.message}  { viewLink ? <a onClick={() => showModal(result.validationErr)}> (View Detailed Errors) </a>: null}
                </span>),
            // description: result.message,
            duration: 5
          })
        }
      } catch (error) {
        console.log('Error uploading file!');
        notify.error({
          message: 'Failed to create report!',
          description: error.message,
          duration:5
        })
      }
    });
    const onHandleExport = useCallback(async(e)=> {
      e.preventDefault();

      await fetch('/api/exportTemp', {
        method: 'POST',
        body: JSON.stringify({
          reportId: info.id,
          clientId: client._id
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      })
      .then(async (response) => {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const responseBody = await response.json();
          if (responseBody.success === 0) {
            notify.error({
              message: 'Failed to download template!',
              description: responseBody.message,
              duration: 5
            });
          } else {
            downloadFile(response);
          }
        } else {
          downloadFile(response);
        }
      })
      .catch(e => {
        console.log("Template export failed!", e.message);
        notify.error({
          message: 'Failed to download template!',
          description: `Please try again. ${e}`,
          duration: 5
        });
      });
      // await createTemplate({
      //   variables:{
      //       reportId: info.id,
      //       clientId: client._id
      //     }
      // }).then(({data, error}) => {
      //   if(data.createReportsExport && !data.createReportsExport.error) {
      //     let a = document.createElement("a");
      //     document.body.appendChild(a);
      //     a.href = '/download/' + data.createReportsExport;
      //     a.click();
      //     document.body.removeChild(a);
      //     console.log("Template exported successfully!");
      //   }
      // }).catch((error) => {
      //   console.log("Template export failed!");
      // });
    // }, [createTemplate, info, client]);
    }, [info, client]);
    // helper function to download the file
    const downloadFile = (response) => {
      response.blob().then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        let clientName = client.name ? client.name.trim().replace(/ /g, '') : client._id;
        a.download = `${clientName}_factsheetTemplate.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }).catch(e => {
        console.log("Failed to process the file download!");
        notify.error({
          message: 'Failed to download template!',
          description: "There was an error processing the file download. Please try again.",
          duration: 5
        });
      });
    };

    const onStartSubmission = useCallback(()=> {
        startSubmission({
          variables:{
            reportId:info.id,
            settings:{
              reportingScale:scale,
              currency
            }

          }
        }).then(({data,errors})=> {

        //  console.log("ERRORS",errors);
          
          refreshScope();
          onStart();

        })
    },[startSubmission,scale,currency,info,client,onStart]);

    let OpenReportList = null;
    var relevantPrevOpenReports = previousOpenReports.filter(item => item.config && item.config.name && item.config.name.value === title);
    if(relevantPrevOpenReports && relevantPrevOpenReports.length>0) {

      OpenReportList =  <Alert className="previousReport"
      // message={`${relevantPrevOpenReports.length} previous reports are still open`}
      type="warning"
      // description={<ReportList data={relevantPrevOpenReports}></ReportList>}
      description={
        <Collapse ghost defaultActiveKey={["1"]} expandIconPosition="right">
          <Panel className="custom-panel-header" header={`${relevantPrevOpenReports.length} previous reports are still open`} key="1">
            <ReportList data={relevantPrevOpenReports} />
          </Panel>
        </Collapse>
      }
      showIcon
     
      // closable
    />
    }
    const showModal = (validationErr) => {
      setActiveTab(Object.keys(validationErr)[0])
      setvalidationError(validationErr)
      setIsModalVisible(true);
    };
    const handleCancel = () => {
      setIsModalVisible(false);
      setvalidationError('')
    };

    // return <Result
    return <div style={{ height: "100vh", overflowY: "auto", margin:"0px 10px"}}><Result style={{ padding: "0px 20px"}}
    // icon={<IconReport />}
    // title={<>Submission of {title}<br/> <Text mark>{contextText}</Text></>}
    icon={null}
    title={<div style={{textAlign:"left"}}><IconReport style={{color: "#1677ff", fontSize: "27px", paddingRight:"10px"}}/>Submission of {title}<Text mark style={{paddingLeft:"20px"}}>{contextText}</Text></div>}
    subTitle={<div style={{textAlign:"left", marginBottom:"10px"}}>Review the reporting sessions and press start to start the submission process of {title}<br/></div>}
    // extra={<><Button loading={loading} type="primary" onClick={onStartSubmission} >Manual Submission</Button>
    // <Input type="file" onChange={onHandleFileChange}></Input>
    // <Button loading={loading} type="primary" onClick={onHandleUpload}>File Submission</Button>
    // <Button type="text" style={{border: "1px solid grey", boxShadow: "0 2px 0 rgba(5, 33, 40, 0.46)"}} icon={<EyeOutlined/>}  onClick={onPreview} >Preview</Button>
    // <Button type="primary" onClick={onHandleExport}>Export Template</Button>
    // </>}
  ></Result>
  <div style={{backgroundColor: "#f5f5f5", padding: "5px 0px", margin:"10px", }}>
    <div style={{
      padding:"0px 32px",
      marginTop:"12px",
      }}>
        {/* <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
            textAlign:"left",
          }}
        >
         <WarningOutlined/> Report all currencies in <Text mark> {currencyMap.get(currency)} ({currency}) </Text>{scale>1 ? <> in a <Text mark>1/{scale} scale</Text><Text type="secondary" style={{fontSize:'0.8em'}}> ({intl.formatNumber(scale,{ style:"currency",currency})} to be reported as {intl.formatNumber(1,{ style:"currency",currency})})</Text></> : null}. <Button onClick={()=>setShowSettingsForm(true)} type="text" size="small" icon={<SettingOutlined/>}>Change settings</Button>
        </Text>
        </Paragraph> */}
        <div className="step1">
          Step1 :  Verify the configurations
        </div>
        {/* <Paragraph> */}
        <div
          style={{
            flexWrap: "wrap",
            maxWidth: "600px", 
            alignItems: "center",
          }}
        >
          {/* <Text */}
          <div
            strong
            style={{
              fontSize: 16,
              textAlign: "left",
              flex: "1 1 auto",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            <WarningOutlined style={{color:"#faad14"}}/> Report all currencies in{" "}
            <Text mark>
              {currencyMap.get(currency)} ({currency})
            </Text>
            {scale >= 1 ? (
              <>
                {" "}
                in a <Text mark>1/{scale} scale</Text>
                <Text type="secondary" style={{ fontSize: "0.8em" }}>
                  ({intl.formatNumber(scale, { style: "currency", currency })} to be
                  reported as{" "}
                  {intl.formatNumber(1, { style: "currency", currency })})
                </Text>
              </>
            ) : null}
            .
          {/* </Text> */}
          
          <Button
            onClick={() => setShowSettingsForm(true)}
            type="text"
            size="small"
            icon={<SettingOutlined />}
            style={{
              marginLeft: "8px",
              whiteSpace: "nowrap",
            }}
          >
            Change settings
          </Button>
        </div>
      </div>
      {/* </Paragraph> */}
        {/* <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >Steps to report:</Text>
        <ul>
          {steps.map((s,idx)=><li key={idx}>{s?.config?.name?.value}</li>)}
        </ul>
      </Paragraph> */}
      {OpenReportList != null ? <><div className="step2">
          Step2 : Review the open Reports
      </div> {OpenReportList} </>: null}
      <Modal open={showSettingsForm} destroyOnClose
      onCancel={()=>setShowSettingsForm(false)}
        onOk={async () => {
          try {
            
            const values = await formInstance?.validateFields();
            formInstance?.resetFields();
            changeSettings(values);
            setShowSettingsForm(false)
          } catch (error) {
            console.log('Failed:', error);
          }
        }}
      ><ReportSettingsForm onFormReady={(instance) => {
        setFormInstance(instance);
      }} /> </Modal>
      </div>
      <div className="uploadReport"
        style={{
          padding: "0px 32px",
        }}
      >
      <div className="step3">
        {OpenReportList != null ? "Step3 : Start Reporting" :
          "Step2 : Start Reporting" }
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginRight: '10px' }}>
          <span
            strong
            style={{
              fontSize: "16px",
              color: "black"
            }}
          >
            Steps to report:
          </span>
        </div>
                <div
          style={{
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginRight: "10px",
          }}
        >
          {steps.map((s, idx) => (
            <div key={idx} style={{ display: "flex", alignItems: "center", }}>
              <div style={{ color: "black", fontSize: "14px", }}>
                {s?.config?.name?.value || `Step ${idx + 1}`}
              </div>
              {idx < steps.length - 1 && (
                <div style={{ marginLeft: "8px", marginRight: "8px", color: "gray", fontSize: "20px", }}>&gt;</div>
              )}
            </div>
          ))}
        </div>
        <div className="preview"
        >
          <Link onClick={onPreview} style={{ textDecoration: "underline" }}>
            <EyeOutlined style={{ paddingRight: "6px" }} />Preview
          </Link>
        </div>
      </div>
    {/* </div>
    <div> */}
      {title!=="Covenants" ?
      <Row style={{marginTop: "15px", 
        }}>
          <Col xs={24} sm={24} md={12} lg={12} style ={{boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            padding: "16px 10px", 
            backgroundColor: "#ffffff", 
            marginBottom: "10px",
            marginRight: "0",
            }} >
            <div style={{ textAlign: "center", width: "100%" }}>
              <Text strong style={{ fontSize: "16px",}}>File Based Submission</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop:"10px" }}>
            {!file?              
            // <Input type="file" onChange={onHandleFileChange} style={{
            //     width: "100%",
            //     borderColor: fileError ? "red" : null,
            //   }}/>
            <div
              style={{
                width: "100%",
                border: fileError ? "2px dashed red" : "2px dashed #ccc",
                borderRadius: "5px",
                textAlign: "center",
                cursor: "pointer",
                color: "#666",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <InboxOutlined style={{ alignContent:"center",fontSize: "48px", color: fileError ? "red" : "#1890ff" }} />
              <p style={{color:"black"}}>click here to upload</p>
              <input
                id="fileInput"
                type="file"
                onChange={onHandleFileChange}
                style={{ display: "none" }}
              />
            </div>
              :
            <div>
              <div>
                <span
                  style={{
                    color: file&& file.status === 'error' ? 'red':"#59cbe8",
                    paddingRight: "5px",
                  }}
                >
                  {file?.name || ""}
                </span>
                <CloseSquareOutlined onClick={fileDelete} />
              </div>
            </div>
            }
            {fileError && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {fileError}
              </span>
            )}
            <div style={{display: "flex", gap: "8px", flexWrap: "wrap"  }}>
              <Button loading={loading} type="primary" style={{ flex: 1 }} onClick={onHandleUpload}>
                Start Submission
              </Button>
              <Button type="primary" style={{ flex: 1,  }} onClick={onHandleExport}>
                Download template
              </Button>
            </div> 
            </div>
          </Col>
          <Col xs={24}sm={24}md={2}lg={2} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "170px", 
                width: "2px",
                backgroundColor: "gray", 
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)", 
                  backgroundColor: "#f5f5f5", 
                  padding: "0 8px",
                  fontWeight: "bold",
                  zIndex: 1,
                  fontSize: "14px",
                }}
              >
                OR
              </span>
            </div>
            <style>
              {`
                @media (max-width: 767px) {
                  div[style*="height: 170px"] {
                    height: 1px !important; /* Horizontal line */
                    width: 100% !important; /* Full width */
                    margin: 10px 0px 15px;
                  }
                  div[style*="height: 170px"] > span {
                    position: absolute !important;
                    top: -10px !important; /* Position text above the line */
                    left: 50% !important;
                    transform: translate(-50%, 0) !important; /* Center text horizontally */
                  }
                }
              `}
            </style>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} style ={{boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
            padding: "16px", 
            backgroundColor: "#ffffff", 
            marginBottom: "10px",
            marginRight: "0",
            }} >
            <div style={{ textAlign: "center", width: "100%" }}>
              <Text strong style={{ fontSize: "16px" }}>Manual Submission</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "11px", marginTop:"10px" }}>
              <Button loading={loading} type="primary" onClick={onStartSubmission}>
                Start Submission
              </Button>
              {/* <Button
                type="text"
                style={{
                  border: "1px solid grey",
                  boxShadow: "0 2px 0 rgba(5, 33, 40, 0.46)",
                }}
                icon={<EyeOutlined />}
                onClick={onPreview}
              >
                Preview
              </Button> */}
            </div>
          </Col>
        </Row>:<div style={{textAlign:"center", margin:"20px 0"}}><Button loading={loading} type="primary" onClick={onStartSubmission}>
                Start Submission
              </Button></div>}
      </div> 
      {/* </Result> */}
      <Modal
        title="Validation Error Details"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        style={{
        maxHeight: "400px", textAlign:"center",
        }}
      >
        <div style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
          {validationErr.fields && validationErr.fields.length > 0 &&(
          <div
            onClick={() => setActiveTab("fields")}
            style={{
              flex: 1,
              textAlign: "center",
              padding: "10px 0",
              cursor: "pointer",
              backgroundColor: activeTab === "fields" ? "#09415d" : "#d1c9c8",
              color: activeTab === "fields" ? "white" : "black",
              borderRadius: "5px 0px 0px 5px"
            }}
          >
            FIELDS
          </div>)}
          {validationErr.values && validationErr.values.length > 0 &&(
          <div
            onClick={() => setActiveTab("values")}
            style={{
              flex: 1,
              textAlign: "center",
              padding: "10px 0",
              cursor: "pointer",
              backgroundColor: activeTab === "values" ? "#09415d" : "#d1c9c8",
              color:activeTab === "values" ? "white" : "black",
              borderRadius: "0px 5px 5px 0px"
            }}
          >
            VALUES
          </div>) }
        </div>
        <div style={{ display: "flex", }}>
          {activeTab === "fields" && validationErr.fields && validationErr.fields.length > 0 && (
            <div style={{ width: "100%", border: "1px solid #ddd", borderRadius: "5px" }}>
              <Table
                columns={fieldsColumns}
                dataSource={validationErr.fields}
                rowKey="incorrect"
                pagination={false}
                scroll={
                  validationErr.fields.length > 3 
                    ? { y: 200 } 
                    : null
                }
                style={{ width: "100%", }}
                rowClassName={() => "custom-row"}
              />
            </div>
          )}
          {activeTab === "values" && validationErr.values && validationErr.values.length > 0 && (
            <div style={{ width: "100%", border: "1px solid #ddd", borderRadius: "5px"}}>
              <Table
                columns={valuesColumns}
                dataSource={validationErr.values}
                rowKey="field"
                pagination={false}
                scroll={
                  validationErr.values.length > 3 
                    ? { y: 200 }
                    : null
                }
                style={{ width: "100%" }}
                rowClassName={() => "custom-row"}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px"
          }}
        >
          <InfoCircleOutlined
            style={{
              fontWeight: "bold",
              fontSize: '20px',
              marginRight: "8px"
            }}
          />
          <span style={{ fontSize: '14px' }}>
            Download correct Template to avoid mistakes
          </span>
        </div>
      </Modal>
    </div>
  </div>
}