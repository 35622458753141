import React from 'react';
import {FormattedMessage} from 'react-intl';
import Display from '../display/index';
import SelectField from '../fields/select/index';
import RequiredLabel from '../RequiredLabel/index';

const reportingFrequencyOptions = [
	{value: 'monthly', label: 'Monthly'},
	{value: 'quarterly', label: 'Quarterly'},
  {value: 'not required', label: 'Not Required'}
];



class InvestmentPeriodicityField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editValue: this.props.periodicity,
			editing: false,
			isValid: !!this.props.periodicity
		};

		this.updatePeriodicity = this.updatePeriodicity.bind(this);
	}

	updatePeriodicity() {
		let confirm = window.confirm('Are you sure you want to change this investment its periodicity?');
		if (confirm) {
			this.props.changeInvestmentPeriodicity(this.state.editValue);
			this.setState(() => ({
				editing: false
			}));
		}
	}

	render() {
		const {
			periodicity,
			canEdit
		} = this.props;

		return (
			this.state.editing ?
				<div className="investment-detail-item editing">
					<label>
						<FormattedMessage id="grid.investments.columns.reportingPeriodicity"/>
					</label>
					<SelectField value={periodicity}
					             options={reportingFrequencyOptions}
					             validations={{required: true}}
					             onValidationChange={(field, valid) => this.setState({isValid: valid})}
					             onChange={(field, value) => this.setState({editValue: value})}/>

					<div className="form-actions" style={{marginTop: '.5em'}}>
						<button onClick={this.updatePeriodicity}
						        style={{width: 'calc(50% - .5em)', marginLeft: '.5em'}}
						        disabled={!this.state.isValid}>
							<FormattedMessage id="app.buttons.save"/>
						</button>
						<button onClick={() => {
							this.setState({editing: false});
						}} className="cancel" style={{width: 'calc(50% - .5em)', marginRight: '.5em'}}>
							<FormattedMessage id="app.buttons.cancel"/>
						</button>
					</div>
				</div>
				:
				<div className={'investment-detail-item ' + (!periodicity ? 'error' : '')}>
					<label>
						<FormattedMessage id="grid.investments.columns.reportingPeriodicity"/>
						{
							!periodicity ? <RequiredLabel/> : null
						}
						{
							canEdit ? <span className="fa fa-cog cog-button" onClick={() => {
								this.setState({editing: true});
							}}>&nbsp;</span> : null}
					</label>
					<Display text={periodicity}/>
				</div>
		);
	}
}

export default InvestmentPeriodicityField;
