import React from "react"
import { FormTreeContent } from "../FormTree"
import { FormFieldLabel } from "./FieldLabel"



export const FormLabelColumn = React.memo(({config,enableToggle=false})=> {
	console.log("config",config)
	console.log("LABELCOLUMN",config.key)

	return <div className={config && config.settings && config.settings.updatedUI ? "form-tree-columnAgro form-tree-label-column" : "form-tree-column form-tree-label-column"}>
		<div className="form-tree-column-header">
				
			</div>
			{FormTreeContent({config,enableToggle,renderField:FormFieldLabel})}
		</div>
})